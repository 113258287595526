import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from '@radix-ui/themes';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { keyframes } from "styled-components";


const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
  to {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
`;


const FAQContainer = styled(Container)`
  background-color: white;
  padding: 4rem 0;
`;

const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;
`;

const StyledItem = styled(AccordionPrimitive.Item)`
  margin-top: 1px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledHeader = styled(AccordionPrimitive.Header)`
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.777rem;
  line-height: 1;
  color: #333;
  cursor: pointer;
  position: relative; // Añadido para la barra indicadora

  &:hover {
    background-color: #f5f5f5;
  }

  & > svg {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }

  &[data-state='open']::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #4CAF50; // Color verde que coincide con tu diseño
    transition: opacity 300ms ease;
  }
`;



const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  font-size: 1.333rem;
  color: #333;
  background-color: #f9f9f9;
  padding: 15px 20px;
  
  // Contenedor interno para el padding
  > div {
    padding: 15px 20px;
  }

  // Animación al abrir
  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Animación al cerrar
  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Estilos adicionales para mejorar la apariencia
  line-height: 1.5;
  will-change: height;
  transition: background-color 200ms ease;

  // Hover effect sutil
  &:hover {
    background-color: #f5f5f5;
  }

  // Asegura que el contenido se mantenga legible
  p {
    margin: 0;
    color: #666;
  }

  // Ajuste para dispositivos móviles
  @media (max-width: 768px) {
    font-size: 13px;
    
    > div {
      padding: 12px 16px;
    }
  }
`;


const AccordionItem = ({ value, question, answer }) => (
  <StyledItem value={value}>
    <StyledHeader>
      <StyledTrigger>
        {question}
        <ChevronDownIcon />
      </StyledTrigger>
    </StyledHeader>
    <StyledContent>{answer}</StyledContent>
  </StyledItem>
);

const FAQ = () => (
  <FAQContainer size="3">
    <Heading align="center" style={{ marginBottom: '5rem', fontSize: '2.369rem'}}>
      Preguntas Frecuentes
    </Heading>
    <StyledAccordion type="single" collapsible defaultValue='item-1'>
      <AccordionItem
        value="item-1"
        question="❓ ¿Qué es SheetSeller?"
        answer="SheetSeller es un complemento para Google Sheets que conecta tu cuenta de vendedor de Mercado Libre con tus hojas de cálculo. Te permite sincronizar, analizar y gestionar tus datos de ventas de forma rápida y sencilla."
      />
      <AccordionItem
        value="item-2"
        question="⚙️ ¿Cómo funciona?"
        answer="Una vez instalado, SheetSeller extrae los datos de tu cuenta de Mercado Libre y los sincroniza automáticamente con Google Sheets. Desde ahí, puedes personalizar tus informes, realizar análisis detallados y tomar decisiones basadas en datos en tiempo real."
      />
      <AccordionItem
        value="item-3"
        question="🔒 ¿Mis datos de vendedor están seguros?"
        answer="¡Absolutamente! 🔐 La seguridad de tus datos es nuestra prioridad. Utilizamos encriptación de nivel industrial y nunca almacenamos tus credenciales de Mercado Libre. Tus datos solo se acceden durante el proceso de sincronización."
      />
      <AccordionItem
        value="item-4"
        question="🔗 ¿Puedo conectar más de una cuenta de Mercado Libre?"
        answer="¡Claro que sí! Con SheetSeller puedes conectar y gestionar varias cuentas de Mercado Libre desde un solo lugar. Ideal para vendedores con múltiples negocios o cuentas."
      />
      <AccordionItem
        value="item-5"
        question="🎁 ¿Ofrecen una prueba gratuita?"
        answer="¡Sí! 🆓 Te ofrecemos una prueba gratuita de 15 días para que explores todas las funciones de SheetSeller sin compromiso. Además, puedes cancelar en cualquier momento si decides que no es para ti."
      />
    </StyledAccordion>
  </FAQContainer>
);

export default FAQ;