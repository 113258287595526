import React from 'react';
import { Box, Container, Text, Heading, Button } from '@radix-ui/themes';
import styled from 'styled-components';
import './css/ReviewsHero.css';

const HeroContainer = styled(Box)`
  background: linear-gradient(45deg, #1E293B, #1a4731);
  color: white;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
`;

const SmallHeading = styled(Text)`
  color: #00B37E;
  font-size: 1.777rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 1.5rem;
  display: block;
`;

const MainHeading = styled(Heading)`
  font-size: 4.209rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  max-width: 800px;
`;

const Description = styled(Text)`
  font-size: 2.369rem;
  line-height: 1.6;
  color: #E1E1E6;
  margin-bottom: 2rem;
  max-width: 700px;
`;



// Efecto de fondo con gradiente y overlay
const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, rgba(0, 179, 126, 0.1), transparent 50%);
`;

const ReviewsHero = () => {
  return (
    <HeroContainer>
      <BackgroundOverlay />
      <StyledContainer size="3">
        <SmallHeading>
          RESEÑAS DE GOOGLE MARKETPLACE
        </SmallHeading>
        <MainHeading>
          SheetSeller Reviews en Google
        </MainHeading>
        <Description>
          Potente y flexible para tu flujo de trabajo personalizado, pero lo suficientemente 
          fácil para que cualquier equipo lo use. 4.7 estrellas, más de 12,000 instalaciones, 
          cientos de vendedores satisfechos. <br /><br />
        </Description>
        <Button size="4" className="hero-button">
          Comenzar Hoy →
        </Button>
      </StyledContainer>
    </HeroContainer>
  );
};

export default ReviewsHero;