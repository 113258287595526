import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from '@radix-ui/themes';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { keyframes } from "styled-components";

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
  to {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
`;


const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;
`;

const StyledItem = styled(AccordionPrimitive.Item)`
  margin-top: 1px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledHeader = styled(AccordionPrimitive.Header)`
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.777rem;
  line-height: 1;
  color: #333;
  cursor: pointer;
  position: relative; // Añadido para la barra indicadora

  &:hover {
    background-color: #dcdcdc;
  }

  & > svg {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }

  &[data-state='open']::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #4CAF50; // Color verde que coincide con tu diseño
    transition: opacity 300ms ease;
  }
`;

const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  font-size: 1.333rem;
  color: #333;
  background-color: #f5f5f5;
  padding: 15px 20px;
  
  // Contenedor interno para el padding
  > div {
    padding: 15px 20px;
  }

  // Animación al abrir
  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Animación al cerrar
  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Estilos adicionales para mejorar la apariencia
  line-height: 1.5;
  will-change: height;
  transition: background-color 200ms ease;

  // Hover effect sutil
  &:hover {
    background-color: #f5f5f5;
  }

  // Asegura que el contenido se mantenga legible
  p {
    margin: 0;
    color: #666;
  }

  // Ajuste para dispositivos móviles
  @media (max-width: 768px) {
    font-size: 13px;
    
    > div {
      padding: 12px 16px;
    }
  }
`;

const FAQContainer = styled(Container)`
  padding: 4rem 0;
  background-color: #f7f7f7; // Cambia el fondo del contenedor a un suave gris claro
`;

const AccordionItem = ({ value, question, answer }) => (
  <StyledItem value={value}>
    <StyledHeader>
      <StyledTrigger>
        {question}
        <ChevronDownIcon />
      </StyledTrigger>
    </StyledHeader>
    <StyledContent>{answer}</StyledContent>
  </StyledItem>
);

const PricingFAQ = () => (
  <FAQContainer size="3">
    <Heading align="center" style={{ marginBottom: '5rem', color: '#111', fontSize: '2.369rem'}}>
      Preguntas Frecuentes
    </Heading>
    <StyledAccordion type="single" collapsible>
      <AccordionItem
        value="item-1"
        question="🆓 ¿Cómo funciona la prueba gratuita?"
        answer="Te ofrecemos 15 días de prueba gratuita para que explores todas las funciones de SheetSeller sin compromiso. ¡Es la mejor forma de descubrir cómo puede ayudarte a gestionar tus ventas! 🚀"
      />
      <AccordionItem
        value="item-2"
        question="🔄 ¿Puedo cambiar de plan en cualquier momento?"
        answer="¡Por supuesto! Puedes actualizar o cambiar tu plan en cualquier momento desde tu perfil. Los cambios se reflejarán automáticamente en tu próxima factura. ✅"
      />
      <AccordionItem
        value="item-3"
        question="📧 ¿Es necesario suscribirme utilizando el correo de mi cuenta de Mercado Libre?"
        answer="¡No es necesario! 😊 Una vez que te suscribas a SheetSeller, tendrás acceso a nuestra plataforma de administración, donde podrás vincular todas las cuentas de Mercado Libre que desees, sin importar el correo que uses para la suscripción. 🔗"
      />
      <AccordionItem
        value="item-4"
        question="📧 ¿Puedo suscribirme sin tener una cuenta de Mercado Pago?"
        answer="¡Claro que sí! No necesitas una cuenta de Mercado Pago para suscribirte. Solo ingresa un correo electrónico válido, y ahí recibirás los cobros mensuales. Es fácil y rápido. ⚡"
      />
      <AccordionItem
        value="item-5"
        question="💳 ¿Qué métodos de pago aceptan?"
        answer="Aceptamos todos los métodos de pago disponibles en Mercado Pago, incluyendo tarjetas de crédito, débito, PayPal y el saldo disponible en tu cuenta de Mercado Pago. ¡Elige el que más te convenga! 🌟"
      />
      <AccordionItem
        value="item-6"
        question="🔒 ¿Es seguro ingresar mis datos bancarios?"
        answer="¡Absolutamente! La seguridad es nuestra prioridad. El formulario de pago es proporcionado por Mercado Pago, una plataforma confiable que protege tus datos con los más altos estándares de seguridad. Nosotros no almacenamos tu información bancaria. 🛡️"
      />
      <AccordionItem
        value="item-7"
        question="❌ Si no tengo una cuenta de Mercado Pago, ¿cómo cancelo la suscripción?"
        answer="Desde tu perfil en nuestra plataforma, puedes cancelar tu suscripción en cualquier momento. Esta acción se comunica directamente con Mercado Pago para finalizar tu plan. Sin complicaciones. 🛑"
      />
    </StyledAccordion>
  </FAQContainer>
);

export default PricingFAQ;