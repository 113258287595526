import React from 'react';
import styled from 'styled-components';
import { Container, Heading, Flex, Text, Button } from '@radix-ui/themes';
import './css/PricingPlan.css';
import { Link as ScrollLink } from 'react-scroll';

const PlansContainer = styled(Container)`
  padding: 4rem 0;
  background-color: #1c1c1c;
`;


const Plan = ({ name, price, features, onSelect }) => (
  <div className="plan-card">
    <Heading style={{ color: '#fff', fontSize: '1.777rem' }}>{name}</Heading>
    <Text style={{ margin: '1rem 0', color: '#fff', fontSize: '1.777rem' }}>${price}/mes</Text>
    <ScrollLink to='PricingCalculator' smooth={true}>
      <Button className="plan-button" size="3" onClick={onSelect}>
        Elegir plan
      </Button>
    </ScrollLink>
    <ul style={{ fontSize: '1rem' }}>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
  </div>
);

const PricingPlans = ({ onPlanSelect }) => {

  return(
    <PlansContainer size="4">
    <Heading align="center" style={{ marginBottom: '5rem', color: '#fff', fontSize: '3.157rem' }}>
      Elige el plan que se adapte a tus necesidades
    </Heading>
    <Flex justify="center" gap="6" wrap="wrap">
      <Plan
        name="Principante"
        price={699}
        features={[
          "Hasta 500 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 24 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Ideal para vendedores que están comenzando o tienen un volumen bajo de ventas."
        ]}
        onSelect={() => onPlanSelect(500)}
      />
      <Plan
        name="Básico"
        price={999}
        features={[
          "Hasta 1,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 24 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Perfecto para negocios en crecimiento."
        ]}
        onSelect={() => onPlanSelect(1000)}
      />
      <Plan
        name="Pro"
        price={1999}
        features={[
          "Hasta 6,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 12 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Ideal para vendedores con un volumen medio-alto de ventas.",
        ]}
        onSelect={() => onPlanSelect(6000)}
      />
      <Plan
        name="Mega"
        price={3999}
        features={[
          "Hasta 15,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 12 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Perfecto para negocios consolidados con alto volumen de ventas.",
        ]}
        onSelect={() => onPlanSelect(15000)}
      />
      <Plan
        name="Enterprise 30k"
        price={5999}
        features={[
          "Hasta 30,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para grandes empresas con necesidades específicas.",
        ]}
        onSelect={() => onPlanSelect(30000)}
      />
      <Plan
        name="Enterprise 50k"
        price={8999}
        features={[
          "Hasta 50,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas con operaciones complejas.",
        ]}
        onSelect={() => onPlanSelect(50000)}
      />
      <Plan
        name="Enterprise 70k"
        price={10999}
        features={[
          "Hasta 70,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas con alto volumen de ventas y necesidades específicas."
        ]}
        onSelect={() => onPlanSelect(70000)}
      />
      <Plan
        name="Enterprise 100k"
        price={14999}
        features={[
          "Hasta 100,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas líderes en su sector.",
        ]}
        onSelect={() => onPlanSelect(100000)}
      />
    </Flex>
  </PlansContainer>
  )
};

export default PricingPlans;