import React from 'react';
import { Box, Flex, Text, Heading, Container } from '@radix-ui/themes';
import overwhelmed from '../../assets/images/overwhelmed.png';
import '../../styles/OldNewWay.css'

const OldWay = () => {
  const items = [
    "Perder tiempo generando reportes manualmente",
    "Cambiar constantemente entre cuentas y plataformas",
    "Copiar y pegar datos, desperdiciando horas valiosas",
    "Trabajar con información desactualizada",
    "Repetir procesos una y otra vez...",
  ];

  return (
    <Container size="3" style={{ backgroundColor: '#ffffff', padding: '80px 0' }}>
      <Box style={{ 
        padding: '30px',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <Flex align="flex-start" justify="space-between" style={{ flexDirection: 'row' }}>
          <Box style={{ flex: 1, marginRight: '40px' }}>
            <Text className='subtitle-way'>
              Cómo funcionan la mayoría de empresas
            </Text>
            <Heading className='title-oldway'>
              La forma antigua
            </Heading>
            <Box as="ul" style={{ listStyleType: 'none', padding: 0 }}>
              {items.map((item, index) => (
                <Flex as="li" key={index} align="center" style={{ marginBottom: '10px' }}>
                  <Box style={{ marginRight: '16px', color: 'red', fontSize: '30px' }}>
                    &times; {/* Esto representa la "X" */}
                  </Box>
                  <Text className='points-ways'>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={overwhelmed} alt="La forma antigua" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default OldWay;