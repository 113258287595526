import React from 'react';
import styled from 'styled-components';
import { Container, Heading, Text, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import './css/PricingHero.css'
import { Link as ScrollLink } from 'react-scroll';

const HeroContainer = styled(Container)`
  background: linear-gradient(45deg, #1E293B, #2D3748);
  color: white;
  padding: 4rem 0;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

const PricingHero = () => (
  <HeroContainer size="5">
    <Heading style={{ marginBottom: '4rem', fontSize: '4.209rem', lineHeight: '4rem' }}>
      Automatiza tus métricas de Mercado Libre en tiempo real.
    </Heading>
    <Text style={{ marginBottom: '3rem', fontSize: '2.369rem' }}>
      Ahorra tiempo con nuestra integración directa a Google Sheets.
    </Text>
    {' '}
    <Text style={{fontWeight: 'bold', fontSize: '1.777rem'}}>
      Prueba gratis por 15 días y encuentra el plan perfecto para tu negocio.
    </Text>
    <ButtonContainer>
      <ScrollLink to='PricingPlans' smooth={true}>
      <Button size="3" className='custom-button-Pricinghero' style={{marginTop: '1rem'}}>
        Ver planes y precios
        <CursorArrowIcon style={{ marginLeft: '8px' }} />
      </Button>
      </ScrollLink>
    </ButtonContainer>
  </HeroContainer>
);

export default PricingHero;