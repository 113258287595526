import React from 'react';
import { Box, Heading, Flex, Card, Text, Button } from '@radix-ui/themes';
import './css/GettingStarted.css';

const StepCard = ({ number, title, description }) => (
  <Card className="step-card">
    <Box>
      <Text className="step-number">{number}</Text>
      <Heading size="4" className="step-title">{title}</Heading>
      <Text className="step-description">{description}</Text>
    </Box>
  </Card>
);

const GettingStarted = () => (
  <Box className="getting-started-container">
    <Box className="getting-started-content">
      <Heading align="center" className="main-heading">
        ¡Empezar a utilizarlo es muy fácil!
      </Heading>
      <Flex direction="row" gap="4" justify="between">
        <StepCard 
          number="1️⃣"
          title="Instala el complemento de Google Sheets"
          description="Encuentra nuestra extensión en Google Workspace y añádela a tu cuenta en segundos." 
        />
        <StepCard 
          number="2️⃣"
          title="Conecta tu cuenta de Sheetseller"
          description="Ingresa las credenciales proporcionadas y sincroniza tus datos de Mercado Libre." 
        />
        <StepCard 
          number="3️⃣"
          title="Descubre un nuevo mundo de información"
          description="Accede a métricas actualizadas, genera reportes personalizados y optimiza tu negocio con facilidad."
        />
      </Flex>
      <Flex justify="center" style={{marginTop:'3rem'}}>
        <Button size="4" className="get-started-button">
          Descubre más →
        </Button>
      </Flex>
    </Box>
  </Box>
);

export default GettingStarted;