import React from 'react';
import { Container, Flex, Box, Text, Link, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import Logo from '../../assets/images/logo.png';
import footerimage from '../../assets/images/footer.jpg'
import '../../styles/Footer.css'
import { Link as PageLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const FooterColumn = ({ title, links }) => (
  <Box style={{ marginBottom: '24px' }}>
    <Text style={{
      fontWeight: 'bold',
      marginBottom: '32px',
      display: 'block',
      color: 'white',
      letterSpacing: '0.5px',
      fontSize: '1.333rem'
    }}>{title}</Text>
    {links.map((link, index) => (
      <Text key={index} size="2" style={{
        display: 'block',
        marginBottom: '20px',
        lineHeight: '1.8'
      }}>
        <Link href="#" style={{
          color: '#999',
          textDecoration: 'none',
          fontSize: '1rem'
        }}>
          {link}
        </Link>
      </Text>
    ))}
  </Box>
);

const Footer = () => {
  const { pathname } = useLocation()
  const handleButtonClick = () => {
    if (pathname === '/pricing') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  return (
    <>
      <Box style={{
        position: 'relative',
        paddingTop: '15rem',
        backgroundColor: 'white'
      }}>
        <Box style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '90%',
          maxWidth: '1200px',
          backgroundColor: '#2D2D3A',
          borderRadius: '12px',
          padding: '48px',
          color: 'white',
          textAlign: 'center',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          marginTop: '2rem',
          zIndex: 2,
          background: `
        linear-gradient(
          rgba(45, 45, 58, 0.95), 
          rgba(45, 45, 58, 0.95)
        ),
        url(${footerimage})
      `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
        }}>
          <Text style={{
            fontWeight: 'bold',
            marginBottom: '32px',
            lineHeight: '1.4',
            fontSize: '4.209rem'
          }}>
            ¿Listo para transformar la forma en que gestionas tu negocio en Mercado Libre? <br />
          </Text>
          <Text style={{
            marginBottom: '40px',
            lineHeight: '2',
            color: '#999',
            fontSize: '1.333rem'
          }}>
            Conecta tus datos automáticamente con Google Sheets y toma decisiones inteligentes basadas en información en tiempo real. <br />
            ✅ Ahorra tiempo y reduce costos operativos. <br />
            ✅ Optimiza tu inventario y maximiza tus ganancias. <br />
            ✅ Simplifica la gestión de tu negocio como nunca antes. <br />
          </Text>
          <Flex justify="center" gap="4" style={{ marginTop: '2rem' }}>
            {
              pathname !== '/pricing' ? (
                <PageLink to="/pricing" style={{ 'textDecoration': 'none' }}>
              <Button size="4" className="custom-button-footer">
                ¡Comienza gratis ahora!
                <CursorArrowIcon style={{ marginLeft: '8px' }} />
              </Button>
            </PageLink>
              ) :(
              <Button size="4" className="custom-button-footer" onClick={handleButtonClick}>
                ¡Comienza gratis ahora!
                <CursorArrowIcon style={{ marginLeft: '8px' }}/>
              </Button>
              )
            }
            <Button size="4" variant="outline-footer" className="outline-button-footer">
              Probar demo
            </Button>
          </Flex>
        </Box>


        <Box style={{
          backgroundColor: '#1E293B',
          color: 'white',
          paddingTop: '220px',
          paddingBottom: '40px',
          position: 'relative',
          zIndex: 1,
          marginTop: '7rem',
        }}>
          <Container size="3">
            <Flex justify="between" wrap="wrap" gap="6" style={{ paddingTop: '13rem' }}>
              <Box style={{ marginBottom: '24px', maxWidth: '300px', marginTop: '40px' }}>
                <img src={Logo} alt="SheetSellerApp Logo" style={{ height: '40px', marginBottom: '16px', display: 'block' }} />
                <Text style={{ display: 'block', lineHeight: '1.5', color: '#999', fontSize: '1rem' }}>
                  Conecta Mercado Libre y Google Sheets para mantener tus datos sincronizados en tus hojas de cálculo 24/7.
                </Text>
              </Box>
              <FooterColumn
                title="Aprende"
                links={['Guía de inicio', 'Documentación', 'Tutoriales', 'Preguntas frecuentes']}
              />
              <FooterColumn
                title="Recursos"
                links={['Blog', 'Plantillas', 'Calculadora de ROI', 'Centro de ayuda']}
              />
              <FooterColumn
                title="Compañía"
                links={['Acerca de nosotros', 'Contacto', 'Política de privacidad', 'Términos de servicio']}
              />
            </Flex>
            <Text size="1" style={{ marginTop: '48px', textAlign: 'center', color: '#999', display: 'block' }}>
              © 2024 SheetSeller. Todos los derechos reservados.
            </Text>
          </Container>
        </Box>
      </Box>
    </>
  )
};

export default Footer;