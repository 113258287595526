import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Theme } from '@radix-ui/themes';
import ScrollToTop from './components/ScrollToTop'
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import Templates from './pages/Templates';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import TemplatePage from './components/templates/TemplatePage';
import Reviews from './pages/Reviews';
import '@radix-ui/themes/styles.css';
//import DemoPage from './pages/Demo';


function App() {
  return (
    <Router basename='/landing'>
      <ScrollToTop />
      <Theme>
        <div style={{ backgroundColor: '#1E293B', minHeight: '100vh' }}>
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/template/:slug" element={<TemplatePage />} />
            {/* Agrega más rutas aquí según sea necesario */}
          </Routes>
          <Footer />
        </div>
      </Theme>
    </Router>
  );
}

export default App;