import React from 'react';
import PricingHero from '../components/pricing/PricingHero';
import PricingCalculator from '../components/pricing/PricingCalculator';
import PricingPlans from '../components/pricing/PricingPlans';
import Testimonials from '../components/common/Testimonials';
import PricingFAQ from '../components/pricing/PricingFAQ';
import SyncBanner from '../components/templates/SyncBanner'
import { Element } from 'react-scroll';

const PricingPage = () => {
  return (
    <>
      <PricingHero />
      <Element name='PricingCalculator'>
        <PricingCalculator />
      </Element>
      <SyncBanner />
      <Testimonials />
      <PricingFAQ />
    </>
  );
};


export default PricingPage;