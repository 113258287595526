import * as React from 'react';
import { Container, Heading, Box, Text, Flex } from '@radix-ui/themes';


const SyncBanner = () => (
  <Container size="6" py="9" style={{
    backgroundColor: 'white',
    color: 'white'
  }}>
    <Box style={{
      background: 'linear-gradient(135deg, #50D5B7, #067D68)',
      borderRadius: '12px',
      padding: '4rem',
      marginTop: '3rem',
      color: 'white',
      textAlign: 'center'
    }}>
      <Flex direction="column" align="center">
        <Text size="7" style={{ marginBottom: '0.5rem', color: '#FFD700' }}> {/* Color dorado para las estrellas */}
          ★★★★★
        </Text>
        <Heading style={{ marginBottom: '1rem', fontSize: '3.157rem', lineHeight: '4rem' }}>
          Más de $400M en ventas sincronizadas en Mercado Libre
        </Heading>
        <Text size="5" style={{ fontSize: '1.777rem', lineHeight: '1.5' }}>
          Empresas de todos los tamaños confían en nuestra tecnología para obtener datos precisos y actualizados a través de nuestro complemento de Google Sheets.
        </Text>
      </Flex>
    </Box>
  </Container>
);


export default SyncBanner;