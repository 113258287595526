import { Text, Flex } from '@radix-ui/themes'
import amazonLogo from '../../assets/images/logos/logo1.png';
import meliLogo from '../../assets/images/logos/logo2.png';
import interpolLogo from '../../assets/images/logos/logo3.png';
import autenticosLogo from '../../assets/images/logos/logo4.png';
import ebaylogo from '../../assets/images/logos/logo5.png';
import '../../styles/TrustedCompanies.css'

const companies = [
  { name: 'Amazon', logo: amazonLogo },
  { name: 'Mercado Libre', logo: meliLogo },
  { name: 'Interpol', logo: interpolLogo },
  { name: 'Autenticos', logo: autenticosLogo },
  { name: 'EBay', logo: ebaylogo },
  { name: 'VAHDAM', logo: 'https://placehold.co/100x50?text=VAHDAM' },
  { name: 'WILDHORN', logo: 'https://placehold.co/100x50?text=WILDHORN' },
  { name: 'ENCASED', logo: 'https://placehold.co/100x50?text=ENCASED' },
  { name: 'RIDGELINE', logo: 'https://placehold.co/100x50?text=RIDGELINE' },
  { name: 'HYPERZON', logo: 'https://placehold.co/100x50?text=HYPERZON' },
];
console.log(companies)
const TrustedCompanies = () => (
  <Flex direction="column" align="center" gap="7" style={{ marginTop: '5rem' }}>
    <Text className='title-trusted' style={{textAlign: 'center'}}>
      📦 Más de 395K órdenes procesadas | 💰 Más de 400M en ventas gestionadas
    </Text>
    <Text style={{fontSize: '2.369rem', textAlign: 'center'}}>
      Nuestra herramienta ya está impulsando el éxito de vendedores como tú, ayudándolos a gestionar sus datos y tomar decisiones basadas en resultados reales.
    </Text>
  </Flex>
);

export default TrustedCompanies;