import React from 'react';
import { Box, Flex, Text, Heading, Container } from '@radix-ui/themes';
import relaxed from '../../assets/images/relaxed.png';

const NewWay = () => {
  const items = [
    "Datos siempre actualizados en tiempo real",
    "Acelera tu flujo de trabajo y ahorra tiempo",
    "Información precisa y confiable directamente de Mercado Libre",
    "Genera reportes personalizados en segundos",
    "Toma decisiones rápidas y basadas en datos actualizados",
  ];

  return (
    <Container size="3" style={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
      <Box style={{
        marginBottom: '50px',
        padding: '30px',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <Flex align="flex-start" justify="space-between" style={{ flexDirection: 'row-reverse' }}>
          <Box style={{ flex: 1, marginLeft: '40px' }}>
            <Text className='subtitle-way'>
              Después de cambiar a SheetSeller
            </Text>
            <Heading className='title-newway'>
              La nueva forma
            </Heading>
            <Box as="ul" style={{ listStyleType: 'none', padding: 0 }}>
              {items.map((item, index) => (
                <Flex as="li" key={index} align="center" style={{ marginBottom: '10px' }}>
                  <Text style={{ marginRight: '16px', color: 'green', fontSize: '30px' }}>
                    ✓ {/* Este símbolo representa la palomita */}
                  </Text>
                  <Text className='points-ways'>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={relaxed} alt="La nueva forma" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default NewWay;